import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import _ from "lodash";
const Info = ({ rows }) => {
  return (
    <div style={{ padding: 22 }}>
      <Grid container spacing={1}>
        {_.map(rows, (row, index) => {
          return (
            <Fragment key={index}>
              <Grid item xs={12} md={4} className="header">
                {row.title}
              </Grid>
              <Grid item xs={12} md={8}>
                {row.href ? (
                  <a href={row.href} target="_blank">
                    {row.content}
                  </a>
                ) : (
                  row.content
                )}
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </div>
  );
};

export default Info;
