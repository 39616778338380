import React, { useEffect, useState } from "react";
import _ from "lodash";

import Stats from "../components/Stats";
import Convert from "./Convert";
import Pool from "./Pool";
import Overlay from "components/Overlay/Overlay";
export default function Stake() {
  return (
    <>
      <div style={{ maxWidth: 1400, margin: "40px auto" }}>
        <Stats />
        <Convert />
        <div className="fieldHeader" style={{ marginTop: 50 }}>
          Stake Curve LP Tokens
        </div>
        {_.map(
          [
            {
              name: "focusCVX",
              token: "focusCVX",
              lpToken: "focusCVX",
              imgUrl: require("assets/img/focusCVX.svg").default,
            },
          ],
          (o) => {
            return <Pool data={o} />;
          }
        )}
      </div>
    </>
  );
}
