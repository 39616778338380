import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "features/home/redux/dashboardSlice";
import gameSlice from "features/home/redux/gameSlice";

const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    game: gameSlice,
  },
});

export default store;
