import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  postScore,
  setScore,
  getLeaderboard,
  getUserRank,
  setSignature,
  postTweet,
  getSessionStatus,
  cleanScore,
  setRandomCode,
  getIsFirstScore,
} from "./redux/gameSlice";
import { useAccount, useSignMessage } from "wagmi";
import ConnectWallet from "../../components/ConnectWallet/ConnectWallet";
import CustomTable from "components/CustomTable/CustomTable";
import CustomTabs from "components/CustomTabs/Tabs";

import { Modal, Container, Grid } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import GifBoxIcon from "@mui/icons-material/GifBox";
import PollIcon from "@mui/icons-material/Poll";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Divider from "@mui/material/Divider";

export default function Game() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [tweetText, setTweetText] = useState("");
  const [userMessage, setUserMessage] = useState("");

  const score = useSelector((state) => state.game.score);
  const signature = useSelector((state) => state.game.signature);
  const tweetStatus = useSelector((state) => state.game.tweetStatus);
  const loggedIn = useSelector((state) => state.game.login);
  const randomCode = useSelector((state) => state.game.randomCode);
  const tweetId = useSelector((state) => state.game.tweetId);
  const isFirstTime = useSelector((state) => state.game.isFirstTime);

  const leaderboard = useSelector((state) => {
    console.log(state.game);
    return state.game.leaderboard;
  });
  const userRank = useSelector((state) => state.game.userRank);

  const { address, isConnecting, isDisconnected } = useAccount();
  const {
    data: signedMessage,
    isError,
    isLoading,
    isSuccess,
    signMessageAsync,
  } = useSignMessage();

  const iframeRef = React.useRef(null);

  const cardStyle = {
    width: "100%",
    borderRadius: "10px",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "10px",
    border: "2px solid white", // 初始邊框顏色為白色
  };

  const iconStyle = {
    marginRight: "10px",
    width: "30px",
    height: "30px",
  };

  const modalContentStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: "black",
    color: "white",
    borderRadius: "25px",
    minHeight: "60vh",
    padding: "20px",
    width: "35%", // 調整為所需的寬度
  };

  const modalButtonStyle = {
    alignSelf: "flex-end", // 使其在右下角
    backgroundColor: "#067AD6",
    color: "white",
    borderRadius: "10px",
    padding: "10px 20px",
    cursor: "pointer",
    border: "none",
    fontFamily:
      '"TwitterChirp", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    fontWeight: "bold",
    marginRight: "10px",
  };

  const textareaStyle = {
    width: "100%",
    height: "50vh",
    padding: "10px",
    background: "transparent",
    color: "white",
    fontFamily:
      '"TwitterChirp", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    fontSize: "25px",
    borderColor: "transparent",
    borderRadius: "5px",
    outline: "none",
    resize: "none", // 禁止調整大小
  };

  // use param in url to get default tab
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");
    if (tab) {
      setTabValue(parseInt(tab));
    }
  }, []);

  const handleSign = async () => {
    let message = `Please sign this message to confirm your score of ${score}.`;

    const obtainedSignature = await signMessageAsync({
      message,
    });

    console.log("obtainedSignature", obtainedSignature);
    if (obtainedSignature) {
      console.log("obtainedSignature", signedMessage);
      setUserMessage(message);
      dispatch(setSignature(obtainedSignature));
    }
  };

  const renderGameorShare = () => {
    if (score && score > 0) {
      return (
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flex: 1 }}>
            <div
              onClick={() => {
                console.log("clicked");
                dispatch(cleanScore());
              }}
            >
              <img src={require("assets/img/back.png")} />
            </div>

            <h2>Your Score:{score}</h2>

            {/* Sign Your Score Card */}
            <div
              style={{
                ...cardStyle,
                borderColor: signature ? "#067AD6" : "white", // 根據 signature 的值調整邊框顏色
                cursor: signature ? "default" : "pointer", // 根據 signature 的值調整游標樣式
                animation: signature ? "" : "blink 1s infinite",
              }}
              onClick={signature ? () => {} : async () => await handleSign()}
            >
              <img
                src={
                  signature
                    ? require("assets/img/sign2.png")
                    : require("assets/img/sign1.png")
                }
                alt="icon"
                style={iconStyle}
              />
              <h2>{signature ? "Score Signed!" : "Sign Your Score"}</h2>
            </div>
            {/* Share Your Score Card */}
            <div
              style={{
                ...cardStyle,
                borderColor: tweetStatus === "Tweeted" ? "#067AD6" : "white",
                cursor: tweetStatus === "Tweeted" ? "default" : "pointer",
                animation: tweetStatus === "Tweeted" ? "" : "blink 1s infinite",
              }}
              onClick={() => {
                if (loggedIn && tweetStatus !== "Tweeted") {
                  setTweetText(
                    `I just earned ${score} $FOCUS token points!\nDiscover how to compound your $CRV and $CVX yields at\n👇🏼👇🏼👇🏼👇🏼👇🏼\nhttps://focus.credit/`
                  );
                  setShowModal(true);
                } else if (!loggedIn) {
                  window.open(
                    "https://api.focus.credit/auth/twitter",
                    "_blank"
                  );
                } else {
                }
              }}
            >
              <img
                src={
                  tweetStatus === "Tweeted"
                    ? require("assets/img/share1.png")
                    : require("assets/img/share1.png")
                }
                alt="icon"
                style={iconStyle}
              />
              <h2>
                {tweetStatus === "Tweeted"
                  ? isFirstTime
                    ? "Score Shared!"
                    : "Score Doubled!"
                  : loggedIn
                  ? isFirstTime
                    ? "Share Your Score"
                    : "Share to DOUBLE Your Score"
                  : "Connect To Share"}
              </h2>
            </div>
            <div
              style={{
                ...cardStyle,
                borderColor:
                  !signature || tweetStatus !== "Tweeted" ? "gray" : "white",
                cursor: !signature
                  ? "default"
                  : !shouldPassTweet()
                  ? "default"
                  : "pointer",
                color:
                  !signature || tweetStatus !== "Tweeted" ? "gray" : "white",
                animation:
                  !signature || tweetStatus !== "Tweeted"
                    ? ""
                    : "blink 1s infinite",
              }}
              onClick={() => {
                if (signature && shouldPassTweet()) {
                  dispatch(
                    postScore({
                      account: address,
                      userMessage: userMessage,
                      score: score,
                      signature: signature,
                      randomCode: randomCode,
                      tweetId: tweetId,
                    })
                  );

                  setTabValue(1);
                } else {
                }
              }}
            >
              <img
                src={require("assets/img/claim2.png")}
                alt="icon"
                style={iconStyle}
              />
              <h2>Claim Focus Points</h2>
            </div>
          </div>
          <img
            src={require("assets/img/05_run.png")}
            style={{
              flex: 1,

              height: "400px",
              objectFit: "contain",
              alignSelf: "center",
            }}
          ></img>
          <Modal
            open={showModal}
            onClose={() => {
              setShowModal(false);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // make background alpha 0.7
              backgroundColor: "rgba(255, 255, 255, 0.3)",
            }}
          >
            <div style={modalContentStyle}>
              <textarea
                value={tweetText}
                onChange={(e) => {
                  setTweetText(e.target.value);
                }}
                style={textareaStyle}
              ></textarea>
              <Divider style={{ background: "gray", border: "solid 1px" }} />
              {
                // make the icons and buttons vertical align
              }
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "35%",
                    justifyContent: "space-between",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  <ImageIcon color="info" sx={{ fontSize: 26 }} />
                  <GifBoxIcon color="info" sx={{ fontSize: 26 }} />
                  <PollIcon color="info" sx={{ fontSize: 26 }} />
                  <SentimentSatisfiedAltIcon
                    color="info"
                    sx={{ fontSize: 26 }}
                  />
                  <CalendarTodayIcon color="info" sx={{ fontSize: 26 }} />
                </div>

                <button
                  style={modalButtonStyle}
                  onClick={() => {
                    dispatch(
                      postTweet({
                        status: tweetText,
                      })
                    );
                    setShowModal(false);
                  }}
                >
                  Tweet
                </button>
              </div>
            </div>
          </Modal>
        </div>
      );
    } else {
      return (
        <div style={{ background: "black" }}>
          <iframe
            ref={iframeRef}
            style={{ width: "100%", height: "80vh" }}
            src="https://resilient-heliotrope-577a5a.netlify.app"
          ></iframe>
        </div>
      );
    }
  };

  const shouldPassTweet = () => {
    if (tweetStatus === "Tweeted" || !isFirstTime) {
      return true;
    } else {
      return false;
    }
  };

  //   const apiRoot = "https://api.flat.credit";
  useEffect(() => {
    if (address) {
      dispatch(getSessionStatus());
      dispatch(getIsFirstScore(address));

      const id = setInterval(() => {
        dispatch(getSessionStatus());
      }, 5000);
      return () => clearInterval(id);
    }
  }, [address]);

  useEffect(() => {
    const handleIframeLoad = () => {
      if (address) {
        console.log("postMessage", address);
        iframeRef.current.contentWindow.postMessage(
          {
            type: "WALLET_ADDRESS",
            payload: address,
          },
          "*" // 請考慮將 '*' 替換成您的遊戲域名，以增加安全性
        );
      } else {
        console.log("no address");
      }
    };

    const iframeElement = iframeRef.current;

    if (iframeElement) {
      iframeElement.addEventListener("load", handleIframeLoad);

      // 清除監聽器，以避免記憶體洩漏
      return () => {
        iframeElement.removeEventListener("load", handleIframeLoad);
      };
    }
  }, [address, tabValue, score]);

  useEffect(() => {
    dispatch(getLeaderboard());

    if (address) {
      dispatch(getUserRank(address));
    }
  }, [address, tabValue]);

  useEffect(() => {
    const handleMessage = async (event) => {
      if (event.data.eventType === "gameOver") {
        console.log("gameOver", event.data.score);
        const userScore = event.data.score;
        const randomCode = event.data.code;
        dispatch(setScore(userScore));
        dispatch(setRandomCode(randomCode));
      }
    };

    window.addEventListener("message", handleMessage);

    // 清理事件監聽器
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [address]);

  return (
    // add background image for the whole page
    // <div style={{backgroundImage: `url(${require("assets/img/background.png")})`}}>

    // 新增四個等寬的image 用 flex 排列, 如果螢幕寬度不夠，則變成垂直排列，並且置中
    // <div style={{display: "flex", justifyContent: "space-around"}}>

    <div
      style={{
        backgroundImage: `url(${require("assets/img/landingBg5.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <style jsx>{`
        @keyframes blink {
          0% {
            box-shadow: 0 0 0 2px white;
          }
          50% {
            box-shadow: 0 0 0 2px transparent;
          }
          100% {
            box-shadow: 0 0 0 2px white;
          }
        }
      `}</style>
      <Container maxWidth="xl" style={{ minHeight: "calc(100vh - 350px)" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            marginBottom: 40,
          }}
        >
          <img src={require("assets/img/step1.png")} />
          <img src={require("assets/img/step2.png")} />
          <img src={require("assets/img/step3.png")} />
          <img src={require("assets/img/step4.png")} />
        </div>

        <CustomTabs
          value={tabValue}
          onChange={(e, newValue) => {
            setTabValue(newValue);
          }}
          tabs={[
            {
              label: "Game",
              content: address ? (
                renderGameorShare()
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    minHeight: "50vh",
                    alignItems: "center",
                    background: "black",
                  }}
                >
                  <ConnectWallet />
                </div>
              ),
            },
            {
              label: "Leaderboard",
              content: (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      border: "5px solid #4B4B4B",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: 20,
                    }}
                  >
                    <CustomTable
                      style={{ padding: "10px", fontFamily: "Tomorrow" }}
                      leftText={{}}
                      maxHeight="calc(100vh - 350px)"
                      headers={["Rank", `Address`, "Points"]}
                      contents={leaderboard.map((row, index) => {
                        return [
                          `#${index + 1}`,
                          `${row.address.slice(0, 7)}...${row.address.slice(
                            -4
                          )}`,
                          `${row.score}`,
                        ];
                      })}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {userRank && (
                      <div
                        className="card"
                        style={{
                          width: "80%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          border: "1px solid #FF2929",
                          fontFamily: "Tomorrow",
                        }}
                      >
                        <div className="dashboardHeader">Your Rank</div>
                        <div>
                          <p>Rank: {userRank.rank}</p>
                          <p>Score: {userRank.score}</p>
                        </div>
                      </div>
                    )}
                    <img
                      src={require("assets/img/leaderboard.png")}
                      alt="Description"
                      style={{ width: "70%" }}
                      onClick={() => {
                        dispatch(cleanScore());
                        setTabValue(0);
                      }}
                    />
                  </Grid>
                </Grid>
              ),
            },
          ]}
        />
      </Container>
    </div>
  );
}
