import React, { useEffect, useState } from "react";
import CustomTabs from "components/CustomTabs/Tabs";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button } from "@mui/material";
import Card from "components/Card/Card";
import CardTitle from "components/Card/CardTitle";
import { styled } from "@mui/material/styles";
import CustomInput from "components/CustomInput/CustomInput";
import { tokens } from "features/configure";
import ButtonSteps from "../components/ButtonSteps";
import Info from "../components/Info";
export default function Pool({ data }) {
  const [tab, setTab] = useState(0);
  const dashboardData = useSelector((state) => state.dashboard);
  const [depositAmount, setDepositAmount] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const StyledContainer = styled((props) => <div {...props} />)({
    padding: 22,
  });

  const renderDeposit = () => {
    return (
      <StyledContainer>
        <div className="header">
          Deposit liquidity into the <a>Focus uncle pool</a> (without staking in
          the Focus gauge), and then stake your LP tokens here to earn CVX on
          top of Focus’s native rewards.
        </div>

        <Grid container>
          <Grid item xs={12} md={6}>
            <CustomInput
              tokens={[tokens[data.name]]}
              title="Amount of tokens to deposit and stake"
              balance={_.get(dashboardData, `data.balances.${data.token}`, 0)}
              value={depositAmount}
              onChange={setDepositAmount}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ButtonSteps
              steps={[
                { label: "Approve", onClick: () => {} },
                { label: "Deposit & Stake", onClick: () => {} },
              ]}
              current={
                _.get(
                  dashboardData,
                  `data.allowances[${data.token}/lpxCVX"]`,
                  0
                ) > 0
                  ? 1
                  : 0
              }
            />
          </Grid>
        </Grid>
      </StyledContainer>
    );
  };

  const renderWithdraw = () => {
    return (
      <StyledContainer>
        <div className="header"></div>
        <Grid container>
          <Grid item xs={12} md={6}>
            <CustomInput
              tokens={[tokens[data.lpToken]]}
              title="Amount of CVX to unstake"
              balance={_.get(dashboardData, `data.balances.${data.lpToken}`, 0)}
              value={withdrawAmount}
              onChange={setWithdrawAmount}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ButtonSteps
              steps={[
                { label: "Approve", onClick: () => {} },
                { label: "Withdraw", onClick: () => {} },
              ]}
              current={
                _.get(
                  dashboardData,
                  `data.allowances[${data.lpToken}/lpxCVX"]`,
                  0
                ) > 0
                  ? 1
                  : 0
              }
            />
          </Grid>
        </Grid>
      </StyledContainer>
    );
  };

  const renderInfo = () => {
    return (
      <Info
        rows={[
          {
            title: "LP token address",
            href: " ",
            content: "0xadd2F542f9FF06405Fabf8CaE4A74bD0FE29c673",
          },
          {
            title: "Deposit contract address",
            href: " ",
            content: "0xadd2F542f9FF06405Fabf8CaE4A74bD0FE29c673",
          },
          {
            title: "Rewards contract address",
            href: " ",
            content: "0xadd2F542f9FF06405Fabf8CaE4A74bD0FE29c673",
          },
          {
            title: "Convex pool id",
            content: "201",
          },
          {
            title: "Convex pool url",
            href: "http://www.focus.com/stake",
            content: "http://www.focus.com/stake",
          },
        ]}
      />
    );
  };

  return (
    <div style={{ margin: "10px auto" }}>
      <Card
        title={
          <CardTitle
            data={[
              {
                label: (
                  <div className="startRow tableHeader">
                    <img className="lgIcon" src={data.imgUrl} />
                    {data.name}
                  </div>
                ),
                size: 3,
              },
              { label: "$0", size: 2 },
              {
                label: "147.81% (proj. 122.58%)",
                title: "veCRV boost: 1.86x",
                size: 3,
              },
              { label: "0 LP", size: 2 },
              { label: "$62,956.21", size: 2 },
            ]}
          />
        }
        content={
          <div>
            <CustomTabs
              value={tab}
              onChange={(e, newValue) => {
                setTab(newValue);
              }}
              tabs={[
                { label: "DEPOSIT", content: renderDeposit() },
                { label: "WITHDRAW", content: renderWithdraw() },
                { label: "INFO", content: renderInfo() },
              ]}
            />
          </div>
        }
      ></Card>
    </div>
  );
}
