import React, { useEffect, useLayoutEffect } from "react";
import {
  fetchDashboard,
  fetchApiData,
  fetchReward,
  reset,
} from "./redux/dashboardSlice";
import { useDispatch } from "react-redux";
import { useAccount } from "wagmi";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import createAppTheme from "./jss/appTheme";
import LandingHeader from "./components/LandingHeader";
import Overlay from "../../components/Overlay/Overlay";
import Footer from "./components/Footer";
export default function App({ children }) {
  const theme = createAppTheme(true);
  const dispatch = useDispatch();
  const { address, isConnected } = useAccount();

  useEffect(() => {
    if (address && isConnected) {
      dispatch(fetchDashboard({ address }));
      // dispatch(fetchReward({ address }));
      // dispatch(fetchApiData({ address }));
      const id = setInterval(() => {
        dispatch(fetchDashboard({ address }));
        // dispatch(fetchReward({ address }));
        // dispatch(fetchApiData({ address }));
      }, 60000);
      // return () => clearInterval(id);
    } else {
      // dispatch(reset());
    }
  }, [address, isConnected]);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Wrapper>
        <div
          style={{
            background: theme.palette.background.main,
            color: "white",
          }}
        >
          <LandingHeader />

          <Outlet />

          <Footer />
        </div>
      </Wrapper>
    </ThemeProvider>
  );
}
