// slice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

axios.defaults.withCredentials = true;
const apiRoot = "https://api.focus.credit";

// Async action to post score to backend with endpoint /api/score in axios
export const postScore = createAsyncThunk(
  "game/postScore",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${apiRoot}/api/score`, {
        address: data.account,
        message: data.userMessage,
        score: data.score,
        signature: data.signature,
        code: data.randomCode,
      });
      if (response) {
        // fetch leaderboard again after posting score
        thunkAPI.dispatch(getLeaderboard());
        thunkAPI.dispatch(getUserRank(data.account));

        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("Error submitting score:", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// function to clean score, tweetStatus, signature
export const cleanScore = createAsyncThunk("game/cleanScore", (a, thunkAPI) => {
  try {
    thunkAPI.dispatch(setScore(0));
    thunkAPI.dispatch(setSignature(""));
    thunkAPI.dispatch(setTweetStatus("NotTweeted"));
  } catch (error) {
    console.error("Error cleaning score:", error);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// function to get user is first time or not from /api/is-first-score/:address
export const getIsFirstScore = createAsyncThunk(
  "game/getIsFirstScore",
  async (address, thunkAPI) => {
    try {
      const response = await axios.get(
        `${apiRoot}/api/is-first-score/${address}`
      );
      if (response) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("Error getting isFirstScore:", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// async function tp post message to twitter with endpoint /api/tweet in axios
export const postTweet = createAsyncThunk(
  "game/postTweet",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${apiRoot}/api/tweet`, data);
      if (response) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("Error submitting score:", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// async function to get sessionStatus from endpoint /api/sessionStatus in axios
export const getSessionStatus = createAsyncThunk(
  "game/getSessionStatus",
  async (thunkAPI) => {
    try {
      const response = await axios.get(`${apiRoot}/auth/session-status`);
      if (response) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("Error submitting score:", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// async function to get leaderboard from endpoint /api/leaderboard in axios
export const getLeaderboard = createAsyncThunk(
  "game/getLeaderboard",
  async (thunkAPI) => {
    try {
      const response = await axios.get(`${apiRoot}/api/leaderboard`);
      if (response) {
        console.log("getLeaderboard", response.data);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("Error submitting score:", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// async function to get userrank from endpoint /api/rank/${address} in axios
export const getUserRank = createAsyncThunk(
  "game/getUserRank",
  async (address, thunkAPI) => {
    try {
      const response = await axios.get(`${apiRoot}/api/rank/${address}`);
      if (response) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("Error submitting score:", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  score: 0,
  signature: "",
  tweetStatus: "NotTweeted", // Could be: NotTweeted, Tweeted, Tweeting, TweetFailed
  login: false,
  leaderboard: [],
  userRank: null,
  randomCode: "",
  isFirstTime: true,
  tweetId: "",
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    setScore: (state, action) => {
      state.score = action.payload;
    },
    setSignature: (state, action) => {
      state.signature = action.payload;
    },
    setTweetStatus: (state, action) => {
      state.tweetStatus = action.payload;
    },
    setRandomCode: (state, action) => {
      state.randomCode = action.payload;
    },
  },
  extraReducers: {
    [postScore.fulfilled]: (state, action) => {
      state.score = 0;
      state.signature = "";
      state.tweetStatus = "NotTweeted";
    },
    [postScore.rejected]: (state, action) => {
      state.score = 0;
      state.signature = "";
      state.tweetStatus = "NotTweeted";
    },
    [postTweet.pending]: (state, action) => {
      state.tweetStatus = "Tweeting";
    },
    [postTweet.fulfilled]: (state, action) => {
      state.tweetStatus = "Tweeted";
      state.tweetId = action.payload["tweetId"];
    },
    [postTweet.rejected]: (state, action) => {
      state.tweetStatus = "TweetFailed";
    },
    [getSessionStatus.fulfilled]: (state, action) => {
      state.login = action.payload["loggedIn"];
    },
    [getSessionStatus.rejected]: (state, action) => {
      state.login = false;
    },
    [getLeaderboard.fulfilled]: (state, action) => {
      console.log("getLeaderboard.fulfilled", action.payload);
      state.leaderboard = action.payload;
    },
    [getLeaderboard.rejected]: (state, action) => {
      state.leaderboard = [];
    },
    [getUserRank.fulfilled]: (state, action) => {
      state.userRank = action.payload;
    },
    [getUserRank.rejected]: (state, action) => {
      state.userRank = null;
    },
    [getIsFirstScore.fulfilled]: (state, action) => {
      state.isFirstTime = action.payload.isFirstScore;
    },
    [getIsFirstScore.rejected]: (state, action) => {
      state.isFirstTime = true;
    },
  },
});

export const { setScore, setSignature, setTweetStatus, setRandomCode } =
  gameSlice.actions;
export default gameSlice.reducer;
