import React from "react";

import LandingContent from "./components/LandingContent";

const Landing = () => {
  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <LandingContent />
    </div>
  );
};

export default Landing;
