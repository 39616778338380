import React, { useEffect, useState } from "react";
import { Grid, Icon, IconButton } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
const LandingContent = () => {
  const [howItWork, setHowItWork] = useState(0);
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("lg"));
  const renderStep = (index, desc, img, extra) => {
    return (
      <Grid item xs={12} lg={3}>
        <div
          style={{
            minHeight: 340,
            maxWidth: 320,
            margin: "20px auto",
            color: "white",
            position: "relative",
            padding: "50px 40px",
          }}
        >
          <div
            style={{
              fontWeight: 700,
              position: "absolute",
              top: "-20px",
              fontSize: 18,
              left: 10,
              fontFamily: "Sarpanch",
            }}
          >
            STEP 0{index}
          </div>
          <img
            src={require("assets/img/step.png")}
            style={{
              objectFit: "contain",
              position: "absolute",
              top: 0,
              left: 10,
              width: 290,
              height: 320,
            }}
          />
          <div style={{ zIndex: 10, position: "relative" }}>
            <div
              style={{
                textAlign: "center",
                margin: "0 auto",
                marginBottom: 0,
              }}
            >
              <img src={img} style={{ height: 120 }} />
            </div>
            <div className="cardDesc">{desc}</div>
          </div>
          {extra && extra}
        </div>
      </Grid>
    );
  };

  return (
    <div>
      <div
        style={{
          textAlign: "center",
          fontSize: 32,
          lineHeight: 1.2,
          letterSpacing: "7px",
        }}
      >
        <img
          src={require("assets/img/header.png")}
          style={{
            maxHeight: 435,
            width: "100%",
            objectFit: "contain",
            marginBottom: 40,
          }}
        />
        <div>
          <span className="coloredText">Focus.credit</span> focuses on
          componding
          <br />
          yields on <span className="coloredText">convex</span> and{" "}
          <span className="coloredText">curve.fi</span>
        </div>
      </div>
      <div
        style={{
          marginTop: 60,
          padding: 34,
          background: "rgba(0, 0, 0, 0.20)",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={1}
          style={{ maxWidth: 1400, margin: "0 auto" }}
        >
          <Grid item xs={12} sm={3}>
            <div className="cardValue">$-</div>
            <div className="cardHeader">TVL</div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className="cardValue">$-</div>
            <div className="cardHeader">TOTAL REVENUE</div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className="cardValue">-</div>
            <div className="cardHeader">TOTAL CRV EARNED</div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className="cardValue">-%</div>
            <div className="cardHeader">% OF FOCUS LOCKED</div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          background: `url(${require(`assets/img/landingBg2.png`)})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <Grid
          container
          justifyContent="center"
          style={{
            maxWidth: 1000,
            margin: "80px auto",
            marginLeft: computer ? "30vw" : "auto",
            fontSize: 16,
            letterSpacing: "3px",
          }}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={6}
            style={{ textAlign: computer ? "right" : "left" }}
            className="cardContainer"
          >
            <div className="cardBgHeader">COMPOUNDER</div>
            <div className="subTitle">
              Boosted yields + Extra rewards
              <br /> Focus Protocol stakes Curve LP tokens in Convex to earn
              boosted rewards plus $CVX. It then periodically claims staking
              rewards and compounds them back into Curve LP to enhance yields.
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="cardContainer">
            <img
              src={require("assets/img/ball1.png")}
              style={{ maxHeight: 320, width: "100%", objectFit: "contain" }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="cardContainer">
            <img
              src={require("assets/img/ball2.png")}
              style={{ maxHeight: 320, width: "100%", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={12} md={6} className="cardContainer">
            <div className="cardBgHeader">MULTI-PROTOCOLS</div>
            <div className="subTitle">
              Support StakeDAO, Yearn, and Convex.
              <br /> Experience compounded yields across the board.
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="cardContainer"
            style={{ textAlign: computer ? "right" : "left" }}
          >
            <div className="cardBgHeader">$FOCUS TOKEN</div>
            <div className="subTitle">
              Stake Focus and join the DAO
              <br />
              Earn extra $FOCUS rewards and platform fees!
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="cardContainer">
            <img
              src={require("assets/img/ball3.png")}
              style={{ maxHeight: 320, width: "100%", objectFit: "contain" }}
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          background: `url(${require(`assets/img/landingBg5.png`)})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          padding: "50px 0",
          paddingBottom: 165,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={require("assets/img/howitwork.png")}
            style={{ objectFit: "contain", maxWidth: "100%" }}
          />
          <div
            style={{
              margin: "55px 20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "100%",
            }}
          >
            <IconButton
              onClick={() => setHowItWork(0)}
              style={{ padding: 0, maxWidth: "50%" }}
            >
              <img
                src={require(`assets/img/lpProvider${
                  howItWork == 0 ? "1" : ""
                }.png`)}
                style={{ objectFit: "scale-down", width: "100%" }}
              />
            </IconButton>
            <IconButton
              onClick={() => setHowItWork(1)}
              style={{ padding: 0, maxWidth: "50%" }}
            >
              <img
                src={require(`assets/img/convert${
                  howItWork == 1 ? "1" : ""
                }.png`)}
                style={{ objectFit: "scale-down", width: "100%" }}
              />
            </IconButton>
          </div>
        </div>
        <Grid
          container
          spacing={2}
          style={{ maxWidth: 1400, margin: "100px auto" }}
          alignItems="center"
        >
          {renderStep(
            1,
            howItWork == 0 ? "Deposit Curve LP" : "Deposit CVX",
            require(`assets/img/step1_${howItWork}.png`),
            <Link to="/stake">
              <img
                src={require(`assets/img/${
                  howItWork == 0 ? "useApp" : "stake"
                }.png`)}
                style={{
                  position: "absolute",
                  right: "-18px",
                  bottom: "-9px",
                  height: 70,
                }}
              />
            </Link>
          )}
          {renderStep(
            2,
            howItWork == 0
              ? "Start earning compounded yields + $FOCUS token"
              : "Mint focusCVX by providing CVX",
            require(`assets/img/step2_${howItWork}.png`)
          )}
          {renderStep(
            3,
            howItWork == 0
              ? "CVX is also earned overtime."
              : "Earn the same rewards as staking on Convex.",
            require(`assets/img/step3_${howItWork}.png`)
          )}
          {renderStep(
            4,
            howItWork == 0
              ? "Withdraw LP when time is up."
              : "Compound Convex’s rewards and briberies back into vlCVX to increase voting power and yields.",
            require(`assets/img/step4_${howItWork}.png`)
          )}
        </Grid>
      </div>

      <div
        style={{
          background: `url(${require(`assets/img/landingBg6.png`)})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          height: 1250,
          margin: "100px 0",
        }}
      >
        <div
          style={{
            maxWidth: 1000,
            margin: "0px auto",
            padding: 20,
            paddingLeft: 60,
            marginLeft: computer ? "35vw" : "auto",
            paddingBottom: 100,
          }}
        >
          <img
            src={require("assets/img/audit.png")}
            style={{
              objectFit: "contain",
              height: 101,
              maxWidth: "100%",
              marginTop: 20,
            }}
          />
          <div
            style={{
              margin: "40px 0",
              textAlign: "left",
              fontSize: 26,
              fontWeight: 400,
              fontFamily: "Noto Sans",
              letterSpacing: 2,
            }}
          >
            Coming Soon
          </div>
          <div style={{ textAlign: "left" }}>
            <Link to={"/stake"}>
              <img
                src={require("assets/img/boost.svg").default}
                style={{
                  objectFit: "contain",
                  maxHeight: 250,
                  maxWidth: "100%",
                }}
              />
            </Link>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 80, textAlign: "center" }}>
        <img
          src={require("assets/img/community.png")}
          style={{ objectFit: "contain", height: 84, maxWidth: "100%" }}
        />
        <div
          style={{
            fontWeight: 600,
            fontSize: 32,
            margin: "40px 0",
            fontFamily: "Sarpanch",
          }}
        >
          Join the community
        </div>
        <Grid container style={{ maxWidth: 900, margin: "30px auto" }}>
          <Grid item xs={12} md={4}>
            <a href="https://t.me/focuscredit" target="_blank">
              <img
                src={require("assets/img/telegram.svg").default}
                style={{ objectFit: "contain", height: 75 }}
              />
            </a>
          </Grid>
          <Grid item xs={12} md={4}>
            <a href="https://twitter.com/focus_credit" target="_blank">
              <img
                src={require("assets/img/twitter.svg").default}
                style={{ objectFit: "contain", height: 75 }}
              />
            </a>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src={require("assets/img/github.svg").default}
              style={{ objectFit: "contain", height: 75 }}
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          background: `url(${require("assets/img/footer.png")})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          minHeight: 350,
          paddingTop: 80,
          marginTop: 100,
        }}
      />
    </div>
  );
};

export default LandingContent;
