import React, { useState } from "react";
import { TextField, Grid, Button, Select, InputAdornment } from "@mui/material";

import BigNumber from "bignumber.js";
import _ from "lodash";

export default function CustomInput(props) {
  const {
    value,
    onChange,
    tokens = [],
    title,
    balance = 0,
    setSelectedToken = () => {},
    selectedToken = _.get(tokens, "[0]", ""),
  } = props;

  function byDecimals(number, tokenDecimals = 18) {
    const decimals = new BigNumber(10).exponentiatedBy(tokenDecimals);
    return new BigNumber(number).dividedBy(decimals);
  }

  const inputFinalVal = (value, total, tokenDecimals) => {
    let inputVal = Number(value.replaceAll(",", ""));
    return inputVal > total
      ? byDecimals(total, 0).toFormat(tokenDecimals)
      : value;
  };
  let inputReg = /[a-z]/i;
  const inputLimitPass = (value, tokenDecimals) => {
    let valueArr = value.split(".");
    return !(
      inputReg.test(value) ||
      (valueArr.length === 2 && valueArr[1].length > tokenDecimals)
    );
  };

  const setMaximumInput = () => {
    const total = balance;
    if (total > 0) {
      const amount = new BigNumber(total).toFormat();
      onChange(amount.replaceAll(",", ""));
    }
  };

  const changeInputValue = (event) => {
    let value = event.target.value;
    const total = balance;
    if (!inputLimitPass(value)) {
      return;
    }

    onChange(inputFinalVal(value, total));
  };

  return (
    <div>
      <div className="betweenRow" style={{ marginBottom: 3, marginTop: 15 }}>
        <div className="header" style={{ fontWeight: 500 }}>
          {" "}
          {title}
        </div>
        <div style={{ fontSize: 12, fontWeight: 500 }}>
          Available: {new BigNumber(balance).toFormat(3)}{" "}
          {_.get(tokens[0], "symbol")}
        </div>
      </div>

      <div>
        <TextField
          value={value}
          onChange={changeInputValue}
          type="number"
          fullWidth
          variant="standard"
          inputProps={{ min: 0, style: { textAlign: "left" } }}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment>
                <Button
                  variant="contained"
                  size="small"
                  style={{ width: 50 }}
                  onClick={setMaximumInput}
                >
                  MAX
                </Button>
                <div
                  style={{
                    background: "rgba(255, 255, 255, 0.5)",
                    borderRadius: 57,
                    height: 50,
                    color: "#04283B",
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                  className="startRow"
                >
                  <img src={_.get(tokens[0], "imgUrl")} className="icon" />
                </div>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
}
