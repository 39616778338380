import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Tabs, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const StyledTabs = styled((props) => <TabList {...props} />)({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    borderBottom: "5px solid #FF2929",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: 600,
    fontSize: "18px",
    fontFamily: "Sarpanch",
    color: "#ffffff",
    padding: "17px 29px",
    minHeight: "60px",
    color: "#7C7C7C",
    "&.Mui-selected": {
      color: "#fff",
      background: "rgba(255, 255, 255, 0.30)",
    },
  })
);

export default function CustomTabs(props) {
  const { tabs, value, onChange, tabStyle, title } = props;

  const renderTabs = () => {
    return (
      <div className="betweenRow">
        {title && (
          <div className="cardTitle" style={{ margin: 0 }}>
            {title}
          </div>
        )}
        <StyledTabs onChange={onChange}>
          {tabs.map((tab, index) => {
            return (
              <StyledTab
                key={tab.label}
                style={{ ...tabStyle }}
                label={tab.label}
                onClick={tab.onClick}
                value={tab.value || index.toString()}
                disabled={tab.disabled}
              />
            );
          })}
        </StyledTabs>
      </div>
    );
  };

  return (
    <TabContext value={value.toString()}>
      {renderTabs()}

      {tabs.map((tab, index) => {
        if (!tab.content) return;
        return (
          <TabPanel
            key={index}
            value={tab.value || index.toString()}
            index={index}
            style={{ padding: "22px" }}
          >
            {tab.content}
          </TabPanel>
        );
      })}
    </TabContext>
  );
}
