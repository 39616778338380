import React, { memo } from "react";
import { Grid } from "@mui/material";

const CardTitle = ({ data }) => {
  return (
    <Grid container alignItems="center">
      {data.map((cell, index) => (
        <Grid key={index} item xs={cell.size}>
          {cell.icon && (
            <img
              alt=""
              src={require("assets/img/" + cell.icon)}
              className="icon"
            />
          )}
          {cell.title && <div className="tableTitle">{cell.title}</div>}
          <span className="tableValue">{cell.label}</span>
        </Grid>
      ))}
    </Grid>
  );
};

export default memo(CardTitle);
