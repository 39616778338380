import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  Modal,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const RewardInfo = ({ apr, title }) => {
  const [open, setOpen] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    color: "#FFFFFF",
    left: "50%",
    width: "80%",
    overflow: "auto",
    maxHeight: "90%",
    transform: "translate(-50%, -50%)",
    maxWidth: "550px",
    background: `#2D2D2D`,
    border: "4px solid #FFF",
    boxShadow: 24,
    borderRadius: "20px",
    p: "20px 24px",
  };

  const renderQuestion = (title, content) => {
    return (
      <Accordion
        disableGutters={true}
        sx={{
          "&:before": {
            display: "none",
          },
          background: "#229FFC",
          borderRadius: "40px",
          ":last-of-type": {
            borderRadius: "40px",
          },

          marginBottom: "20px",
          boxShadow: "1px 1px 1px 0px rgba(0, 0, 0, 0.10)",
          "& .Mui-expanded": {
            borderRadius: "40px 40px 0 0",
            borderBottom: 0,
          },
          "& .MuiAccordion-region": {
            borderRadius: "0 0 40px 40px",
            borderTop: 0,
          },
          "&:hover": {},
        }}
      >
        <AccordionSummary
          sx={{
            "& .Mui-expanded": { border: 0, marginBottom: 0 },
            maxWidth: "100%",
            height: "60px",
          }}
          expandIcon={<img src={require("assets/img/expand.png")} />}
        >
          <div className="startRow">
            <InfoIcon
              sx={{ color: "#FFFFFF", marginLeft: "20px", marginRight: "10px" }}
            />{" "}
            {title}
          </div>
        </AccordionSummary>
        <AccordionDetails
          style={{
            background: "#2D2D2D",
            borderRadius: "0 0 30px 30px",
            padding: 20,
          }}
        >
          {content}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <span style={{}}>
      <IconButton onClick={() => setOpen(true)}>
        <InfoIcon sx={{ color: "#FFFFFF" }} />
      </IconButton>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <div style={{ marginBottom: 22, fontSize: 24, fontWeight: 600 }}>
            {title}
          </div>
          <div className="card">
            <div className="betweenRow listHeader">
              <div>Current vAPR</div>
              <div>{apr}%</div>
            </div>
          </div>
          {renderQuestion("Where do rewards accrue?", "Coming soon")}
          {renderQuestion("What does vAPR stand for?", "Coming soon")}
        </Box>
      </Modal>
    </span>
  );
};

export default RewardInfo;
