import React from "react";
import { ListItem, ListItemText, Box, Hidden } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const renderListItem = (name, color, linkTo, location, shine) => {
  const selected = location == linkTo;

  return (
    <LinkButton
      color={color}
      style={{ padding: 0 }}
      title={name}
      href={linkTo}
      selected={selected}
      shine={shine}
    />
  );
};
const MenuItems = ({ style, color = "#ffffff", flexDirection = "row" }) => {
  const location = useLocation();
  let currentLocation = location.pathname;
  return (
    <>
      <style jsx>{`
        @keyframes blink {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
      <Box
        display="flex"
        justifyContent={"flex-start"}
        flexDirection={flexDirection}
        alignItems={"center"}
        style={{ ...style, marginLeft: "-16px" }}
      >
        {renderListItem("Stake", color, "/stake", currentLocation)}
        {renderListItem("Claim", color, "/claim", currentLocation)}
        {renderListItem("Lock Focus", color, "/lock", currentLocation)}
        {renderListItem("Game", "red", "/game", currentLocation, true)}
      </Box>
    </>
  );
};

function LinkButton({ style, title, href, color, selected, shine }) {
  return (
    <div style={{ ...style, paddingLeft: 20 }}>
      <Link
        to={href}
        style={{
          textDecoration: "none",
        }}
      >
        <div
          style={{
            color,
            margin: "2px 10px",
            fontWeight: 600,
            fontSize: 25,
            letterSpacing: 5,
            padding: 4,
            borderBottom: selected ? "5px solid #FF2929" : "0px",
            animation: shine ? "blink 0.8s infinite" : "",
          }}
        >
          {title}
        </div>
      </Link>
    </div>
  );
}

export default MenuItems;
