import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import {
  contracts,
  getNftUrl,
  iconsUrl,
  pirexCvxABI,
  pools,
  priceUrl,
  rewardsUrl,
  tokens,
} from "features/configure";
import axios from "axios";
import { readContracts, readContract } from "@wagmi/core";
import { convertAmountFromRawNumber } from "features/helpers/bignumber";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import BigNumber from "bignumber.js";

const genesis_epoch = 1689811200;

const initialState = {
  fetchDashboardPending: false,
  fetchRewardPending: false,
  data: {},
  reward: {},
};

const getRedeemedIndexes = (redeemedValue) => {
  let redeemedIndexes = [];
  let index = 0;
  while (redeemedValue > 0) {
    if (redeemedValue & 1) {
      redeemedIndexes.push(index);
    }
    redeemedValue = redeemedValue >> 1;
    index++;
  }
  return redeemedIndexes;
};

export const fetchReward = createAsyncThunk(
  "dashbaord/fetchReward",
  async ({ address }) => {
    const pirexCVXContract = {
      address: contracts["PirexCVX"].address,
      abi: contracts["PirexCVX"].abi,
    };

    const uncleContract = {
      address: tokens["uncleCVX"].address,
      abi: tokens["uncleCVX"].abi,
    };
    const rfnContract = {
      address: contracts["RFN"].address,
      abi: contracts["RFN"].abi,
    };

    const epochId = await readContract({
      address: pirexCVXContract.address,
      abi: pirexCVXContract.abi,
      functionName: "getCurrentEpoch",
    });

    // remove the last string from epochId
    let current_epoch_string = epochId.toString();

    let result = { snapshotRewards: [], futuresRewards: [] };

    for (
      let epochi = genesis_epoch;
      epochi <= parseFloat(current_epoch_string);
      epochi += 1209600
    ) {
      const epochString = epochi.toString();

      const epoch = await readContract({
        address: uncleContract.address,
        abi: uncleContract.abi,
        functionName: "getEpoch",
        args: [epochString],
      });

      // call toString() for all elements in epoch
      let serializedEpoch = {};

      serializedEpoch.snapshotId = epoch[0].toString();
      serializedEpoch.rewards = _.map(epoch[1], (e1) => {
        // remove 24 letter from right
        return e1.slice(0, -24);
      });

      serializedEpoch.snapshotRewards = _.map(epoch[2], (e2) => {
        return e2.toString();
      });

      serializedEpoch.futuresRewards = _.map(epoch[3], (e3) => {
        return e3.toString();
      });

      let contractData = [];
      contractData.push({
        ...uncleContract,
        functionName: "balanceOfAt",
        args: [address, serializedEpoch.snapshotId],
      }); //0

      contractData.push({
        ...uncleContract,
        functionName: "totalSupplyAt",
        args: [serializedEpoch.snapshotId],
      }); //1
      contractData.push({
        ...uncleContract,
        functionName: "getEpochRedeemedSnapshotRewards",
        args: [address, epochString],
        //2
      });
      contractData.push({
        ...rfnContract,
        functionName: "balanceOf",
        args: [address, epochString],
      }); //3

      contractData.push({
        ...rfnContract,
        functionName: "totalSupply",
        args: [epochString],
      }); //4

      const data = await readContracts({
        contracts: contractData,
      });

      const balanceOfAt = new BigNumber(
        _.get(data, `[${0}].result`, 0).toString()
      );

      const totalSupplyAt = new BigNumber(
        _.get(data, `[${1}].result`, 0).toString()
      );

      const redeemedValue = _.get(data, `[${2}].result`, 0).toString();

      const redeemedIndexes = getRedeemedIndexes(redeemedValue);

      const rbalanceOf = new BigNumber(
        _.get(data, `[${3}].result`, 0).toString()
      );

      const rtotalSupply = new BigNumber(
        _.get(data, `[${4}].result`, 0).toString()
      );

      result.snapshotRewards = [
        ...result.snapshotRewards,
        serializedEpoch.snapshotRewards.map((reward, index) => {
          const rewardAmount = new BigNumber(reward)
            .times(balanceOfAt)
            .div(totalSupplyAt);
          const isClaimed = redeemedIndexes.includes(index);
          return {
            address: serializedEpoch.rewards[index],
            rewardAmount: rewardAmount.toString(),
            rewardIndex: index,
            isClaimed,
            epoch: epochString,
          };
        }),
      ];

      result.futuresRewards = [
        ...result.futuresRewards,
        serializedEpoch.futuresRewards.map((reward, index) => {
          if (rtotalSupply == 0) {
            return {
              address: serializedEpoch.rewards[index],
              rewardAmount: 0,
              epoch: epochString,
            };
          } else {
            const rewardAmount = new BigNumber(reward)
              .times(rbalanceOf)
              .div(rtotalSupply);
            return {
              address: serializedEpoch.rewards[index],
              rewardAmount: rewardAmount.toString(),
              epoch: epochString,
            };
          }
        }),
      ];
    }

    const futuresRewards = _.get(result, "futuresRewards");
    const snapshotRewards = _.get(result, "snapshotRewards");

    const rewards = {};
    const rewardDetails = {};

    handleRewardData(
      snapshotRewards,
      rewards,
      rewardDetails,
      "snapshotRewards"
    );
    handleRewardData(futuresRewards, rewards, rewardDetails, "futuresRewards");

    for (let tokenAddress of Object.keys(rewards)) {
      const priceData = await axios.get(priceUrl + tokenAddress);
      const iconData = await axios.get(iconsUrl + tokenAddress);

      rewards[tokenAddress]["coin"] = {
        name: _.toUpper(_.get(iconData, "data.symbol", "")),
        icon: _.get(iconData, "data.image.small", ""),
      };
      rewards[tokenAddress]["price"] = _.get(
        priceData,
        `data.coins[ethereum:${tokenAddress}].price`,
        0
      );
    }

    return { rewards, rewardDetails };
  }
);

export const fetchDashboard = createAsyncThunk(
  "dashbaord/fetchDashboard",
  async ({ address }) => {
    try {
      const cvxContract = {
        address: tokens["CVX"].address,
        abi: tokens["CVX"].abi,
      };
      const pirexCVXContract = {
        address: contracts["PirexCVX"].address,
        abi: contracts["PirexCVX"].abi,
      };
      const rfnContract = {
        address: contracts["RFN"].address,
        abi: contracts["RFN"].abi,
      };
      const lockerContract = {
        address: contracts["cvxLockerV2"].address,
        abi: contracts["cvxLockerV2"].abi,
      };
      const focusContract = {
        address: tokens["focusCVX"].address,
        abi: tokens["focusCVX"].abi,
      };
      const uncleContract = {
        address: tokens["uncleCVX"].address,
        abi: tokens["uncleCVX"].abi,
      };
      const curvePoolContract = {
        address: contracts["curvePool"].address,
        abi: contracts["curvePool"].abi,
      };

      let contractData = [];
      contractData.push({
        ...cvxContract,
        functionName: "balanceOf",
        args: [address],
      }); //0

      contractData.push({
        ...uncleContract,
        functionName: "balanceOf",
        args: [address],
      }); //1
      contractData.push({
        ...focusContract,
        functionName: "balanceOf",
        args: [address],
        //2
      });
      contractData.push({
        ...cvxContract,
        functionName: "allowance",
        args: [address, contracts["PCvxZaps"].address],
      }); //3

      contractData.push({
        ...uncleContract,
        functionName: "allowance",
        args: [address, focusContract.address],
      }); //4
      contractData.push({
        ...focusContract,
        functionName: "previewDeposit",
        args: [1e18],
      }); //5

      contractData.push({
        ...focusContract,
        functionName: "previewRedeem",
        args: [1e18],
      }); //6
      contractData.push({
        ...cvxContract,
        functionName: "allowance",
        args: [address, contracts["lpxCVX"].address],
      }); //7

      contractData.push({
        ...uncleContract,
        functionName: "allowance",
        args: [address, contracts["lpxCVX"].address],
      }); //8

      contractData.push({
        ...curvePoolContract,
        functionName: "get_dy",
        args: [0, 1, 1e18],
      }); //9
      contractData.push({
        ...curvePoolContract,
        functionName: "get_dy",
        args: [1, 0, 1e18],
      }); //10

      contractData.push({
        ...cvxContract,
        functionName: "allowance",
        args: [address, contracts["PirexCVX"].address],
      }); //11

      contractData.push({
        ...rfnContract,
        functionName: "isApprovedForAll",
        args: [address, contracts["PirexCVX"].address],
      }); //12

      contractData.push({
        ...uncleContract,
        functionName: "allowance",
        args: [address, contracts["PirexCVX"].address],
      }); //13

      contractData.push({
        ...lockerContract,
        functionName: "lockedBalances",
        args: [contracts["PirexCVX"].address],
      }); //14

      contractData.push({
        ...pirexCVXContract,
        functionName: "MAX_REDEMPTION_TIME",
        args: [],
      }); //15

      contractData.push({
        ...lockerContract,
        functionName: "lockedBalanceOf",
        args: [contracts["PirexCVX"].address],
      }); // 16

      contractData.push({
        ...uncleContract,
        functionName: "balanceOf",
        args: [contracts["uncleFocusStrategy"].address],
      }); // 17

      const data = await readContracts({
        contracts: contractData,
      });

      const balances = {};
      const allowances = {};
      const preview = {};
      const stats = {};
      balances["CVX"] = convertAmountFromRawNumber(
        _.get(data, `[${0}].result`, 0)
      );
      balances["uncleCVX"] = convertAmountFromRawNumber(
        _.get(data, `[${1}].result`, 0)
      );
      balances["focusCVX"] = convertAmountFromRawNumber(
        _.get(data, `[${2}].result`, 0)
      );
      allowances["CVX/PCvxZaps"] = convertAmountFromRawNumber(
        _.get(data, `[${3}].result`, 0)
      );
      allowances["uncleCVX/focusCVX"] = convertAmountFromRawNumber(
        _.get(data, `[${4}].result`, 0)
      );

      preview["deposit"] = convertAmountFromRawNumber(
        _.get(data, `[${5}].result`, 0)
      );
      preview["redeem"] = convertAmountFromRawNumber(
        _.get(data, `[${6}].result`, 0)
      );
      allowances["CVX/lpxCVX"] = convertAmountFromRawNumber(
        _.get(data, `[${7}].result`, 0)
      );
      allowances["uncleCVX/lpxCVX"] = convertAmountFromRawNumber(
        _.get(data, `[${8}].result`, 0)
      );
      preview["CVXSwap"] = convertAmountFromRawNumber(
        _.get(data, `[${9}].result`, 0)
      );
      preview["uncleCVXSwap"] = convertAmountFromRawNumber(
        _.get(data, `[${10}].result`, 0)
      );
      allowances["CVX/PirexCVX"] = convertAmountFromRawNumber(
        _.get(data, `[${11}].result`, 0)
      );
      allowances["RFN/PirexCVX"] = _.get(data, `[${12}].result`, false);
      allowances["uncleCVX/PirexCVX"] = convertAmountFromRawNumber(
        _.get(data, `[${13}].result`, 0)
      );
      const lockedBalances = _.get(data, `[${14}].result[3]`, []);

      let serializedLockedBalances = [];
      for (let i = 0; i < lockedBalances.length; i++) {
        let lockedBalance = lockedBalances[i];
        let serializedLockedBalance = {};
        serializedLockedBalance.amount = lockedBalance["amount"].toString();
        serializedLockedBalance.boosted = lockedBalance["boosted"].toString();
        serializedLockedBalance.unlockTime = lockedBalance["unlockTime"];

        serializedLockedBalances.push(serializedLockedBalance);
      }

      const MAX_REDEMPTION_TIME = _.get(data, `[${15}].result`);
      const redemptionsContract = [];
      for (let sBlanace of serializedLockedBalances) {
        redemptionsContract.push({
          ...pirexCVXContract,
          functionName: "redemptions",
          args: [sBlanace.unlockTime],
        });
      }
      const redemptionsData = await readContracts({
        contracts: redemptionsContract,
      });
      const redemptions = _.map(redemptionsData, "result");

      let serializedRedemptions = _.map(redemptions, (redemption, index) => {
        return redemption.toString();
      });

      const priceData = {};
      const cvxPrice = _.get(
        priceData,
        `data.coins[ethereum:${cvxContract.address}].price`,
        0
      );

      stats["allTVL"] = convertAmountFromRawNumber(
        new BigNumber(_.get(data, `[${16}].result`, 0)).times(
          new BigNumber(cvxPrice)
        )
      );

      stats["focusTVL"] = parseFloat(
        convertAmountFromRawNumber(
          new BigNumber(_.get(data, `[${17}].result`, 0)).times(
            new BigNumber(cvxPrice)
          )
        )
      ).toFixed(2);

      stats["uncleTVL"] = parseFloat(
        stats["allTVL"] - stats["focusTVL"]
      ).toFixed(2);

      return {
        balances,
        allowances,
        preview,
        stats,
        lockedBalances: serializedLockedBalances,
        MAX_REDEMPTION_TIME,
        redemptions: serializedRedemptions,
      };
    } catch (err) {
      enqueueSnackbar(_.get(err, "message"), {
        variant: "error",
      });
      throw err;
    }
  }
);

const handleRewardData = (data, rewards, rewardDetails, type) => {
  if (!data) return;
  for (let i = 0; i < data.length; i++) {
    const epochData = data[i];
    for (let d of epochData) {
      if (!rewards[d.address]) {
        rewards[d.address] = { value: 0 };
      }
      if (!rewardDetails[d.epoch]) {
        rewardDetails[d.epoch] = { snapshotRewards: [], futuresRewards: [] };
      }
      if (d.isClaimed) continue;
      if (d.rewardAmount == 0) continue;
      rewards[d.address]["value"] += parseFloat(
        convertAmountFromRawNumber(
          d.rewardAmount,
          d.address == "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48" ? 6 : 18
        )
      );
      rewardDetails[d.epoch][type].push(d);
    }
  }
};

export const fetchApiData = createAsyncThunk(
  "dashbaord/fetchApiData",
  async ({ address }) => {
    try {
      const nftData = await axios.get(getNftUrl + address);

      const redemptionList = _.get(nftData, "data.ownedNfts", []);

      return { redemptionList };
    } catch (err) {
      enqueueSnackbar(_.get(err, "message"), {
        variant: "error",
      });
      throw err;
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    reset: () => initialState,
    // todoDeleted(state, action) {
    //   delete state.entities[action.payload];
    // },
    // todoToggled(state, action) {
    //   const todoId = action.payload;
    //   const todo = state.entities[todoId];
    //   todo.completed = !todo.completed;
    // },
    // allTodosCompleted(state, action) {
    //   Object.values(state.entities).forEach((todo) => {
    //     todo.completed = true;
    //   });
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboard.pending, (state, action) => {
        state.fetchDashboardPending = true;
      })
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        state.data = action.payload;
        state.fetchDashboardPending = false;
      })
      .addCase(fetchDashboard.rejected, (state, action) => {
        state.fetchDashboardPending = false;
      })
      .addCase(fetchApiData.pending, (state, action) => {
        state.fetchApiPending = true;
      })
      .addCase(fetchApiData.fulfilled, (state, action) => {
        state.apiData = action.payload;
        state.fetchApiPending = false;
      })
      .addCase(fetchApiData.rejected, (state, action) => {
        state.fetchApiPending = false;
      })
      .addCase(fetchReward.pending, (state, action) => {
        state.fetchRewardPending = true;
      })
      .addCase(fetchReward.fulfilled, (state, action) => {
        state.reward = action.payload;
        state.fetchRewardPending = false;
      })
      .addCase(fetchReward.rejected, (state, action) => {
        state.fetchRewardPending = false;
      });
  },
});

export const { reset } = dashboardSlice.actions;
export default dashboardSlice.reducer;
