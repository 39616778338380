import {
  erc1155ABI,
  RFNABI,
  pirexCvxABI,
  lpxCvxABI,
  cvxLockerV2ABI,
  curvePoolABI,
  PCvxZapsABI,
  uncleFocusStrategyABI,
} from "./abi";
export const contracts = {
  upxCvx: {
    address: "0x7B8eA9e13c59f965D7F18fCe17CCaBc47721AcE2",
    abi: erc1155ABI,
  },
  RFN: {
    address: "0x3B37966e7f96Df77F5965F0208009296D0dFFe01",
    abi: RFNABI,
  },
  PirexCVX: {
    address: "0x0e45703642343aD06e5909711bF11EA2094F1A1B",
    abi: pirexCvxABI,
  },
  lpxCVX: {
    address: "0x6270234E3eDA5370aA7224696a6Dc9263973C3e8",
    abi: lpxCvxABI,
  },
  cvxLockerV2: {
    address: "0x72a19342e8f1838460ebfccef09f6585e32db86e",
    abi: cvxLockerV2ABI,
  },

  PCvxZaps: {
    address: "0xdFa417D1183E67787738FF6dca9d8aea1D7F3379",
    abi: PCvxZapsABI,
  },
  curvePool: {
    address: "0x317412f33a4cb32B32CC8011F3D22accb599C520",
    abi: curvePoolABI,
  },
  uncleFocusStrategy: {
    address: "0x3061579E7dF4e618D9b657ccdE6c52Fb4ca0d439",
    abi: uncleFocusStrategyABI,
  },
};
