import { createTheme } from "@mui/material/styles";
const createAppTheme = () =>
  createTheme({
    palette: {
      primary: { main: "#1D6FFF" },
      secondary: { main: "#F83B53" },
      background: {
        main: `url(${require(`assets/img/landingBg.png`)})`,
      },
    },
    typography: {
      fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
      fontSize: 12,
    },
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            fontSize: "20px",
            fontWeight: 500,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            background: "#fff",
            border: "1px solid #7C7C7C",
            borderRadius: 10,
            color: "#2B2B2B",
            padding: "10px 10px",
            marginBottom: 10,
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            background: "#037381",
            borderRadius: "5px",
            color: "#FFFFFF",
            fontWeight: 400,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#FFFFFF",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            background: "#283246",
            color: "#FFFFFF",
            fontWeight: 500,
            fontSize: 18,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: "20px",
            fontWeight: 500,
            "&.Mui-selected": {
              background: "#F76356",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            padding: 0,
            border: 0,
            boxShadow: "none",
            background: "rgba(255, 255, 255, 0.5)",
            borderRadius: 57,
            "&:hover": {
              background: "rgba(255, 255, 255, 0.5)",
              borderRadius: 57,
            },
            "&:focus": {
              background: "rgba(255, 255, 255, 0.5)",
              borderRadius: 57,
            },
            minHeight: 50,
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "& .MuiInputBase-input": {
              fontFamily: "Poppins",
              background: "transaprent",
              color: "#04283B",
              fontWeight: 500,
              fontSize: 20,
              padding: "0 12px",
            },
          },
        },
      },

      MuiMenu: {
        styleOverrides: {
          paper: {
            background: "#a5a5a5",
            color: "#04283B",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#ffffff",
            "& .MuiSvgIcon-root": {
              fontSize: 30,
            },
            "&.Mui-checked": { color: "#2DC991" },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            margin: "8px",
            borderRadius: "10px",
            fontSize: "18px",
            lineHeight: "24px",
            fontWeight: 500,
            padding: "15px 22px",
            textTransform: "none",
            fontFamily: "Poppins",
            "&.Mui-disabled": {
              background: "#C1C1C1",
              color: "#7C7C7C",
            },
          },
          containedPrimary: {
            backgroundSize: "cover",
            color: "#ffffff",
            fontWeight: 700,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          },
          containedSecondary: {},
          outlinedSecondary: {
            border: "3px solid #037381",
            backgroundColor: "#283246",
            color: "#FFFFFF",
            "&:hover": {
              border: "3px solid #037381",
            },
            "&:active": {
              border: "3px solid #037381",
            },
            "&:focus": {
              border: "3px solid #037381",
            },
          },
          sizeSmall: {
            padding: "9px 22px",
            fontSize: "16px",
            fontWeight: 500,
          },
          sizeLarge: {
            padding: "18px 38px",
            fontSize: "28px",
            lineHeight: "34px",
            fontWeight: 700,
            margin: 12,
            fontFamily: "Bona Nova",
          },
        },
      },
    },
  });

export default createAppTheme;
