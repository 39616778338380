import React from "react";
import { createRoot } from "react-dom/client";
import Root from "./Root";
import * as serviceWorker from "./serviceWorker";
import "./App.css";

const domNode = document.getElementById("root");
const root = createRoot(domNode);
root.render(<Root />);
serviceWorker.unregister();
