import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Stepper, Step, StepLabel, Button } from "@mui/material";
import _ from "lodash";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 9px)",
    right: "calc(50% + 9px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1D6FFF",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1D6FFF",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#7C7C7C",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#1D6FFF",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#1D6FFF",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {String(props.icon)}
    </ColorlibStepIconRoot>
  );
}
const ButtonSteps = ({ steps, current }) => {
  return (
    <div style={{ marginTop: 15 }}>
      <Stepper
        activeStep={current}
        alternativeLabel
        connector={<QontoConnector />}
      >
        {_.map(steps, (step, index) => (
          <Step
            key={index}
            sx={{
              "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                marginTop: "2px",
              },
            }}
          >
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <Button
                variant="contained"
                disabled={current !== index}
                onClick={step.onClick}
                style={{
                  width: step.width || 200,
                }}
              >
                {step.label}
              </Button>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default ButtonSteps;
