export {
  pxCvxABI,
  uCvxABI,
  erc1155ABI,
  RFNABI,
  pirexCvxABI,
  lpxCvxABI,
  cvxLockerV2ABI,
  cvxABI,
  PCvxZapsABI,
} from "./abi";
export { tokens } from "./tokens";
export { pools } from "./pools";
export { contracts } from "./contracts";
export const rewardsUrl = "https://pirex.io/api/1/convex/rewards/";
export const getNftUrl =
  "https://eth-mainnet.g.alchemy.com/nft/v2/NMfny0oE8hAQ5tueVKfzX8tpqAIUPeM3/getNFTs?contractAddresses[]=0x7B8eA9e13c59f965D7F18fCe17CCaBc47721AcE2&withMetadata=true&pageSize=100&owner=";
export const iconsUrl =
  "https://api.coingecko.com/api/v3/coins/ethereum/contract/";
export const priceUrl = "https://coins.llama.fi/prices/current/ethereum:";
export const MAX =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";
