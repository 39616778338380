import React, { useEffect, useState, memo } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

const Card = ({ title, content, switchable = true, expanded }) => {
  return (
    <Accordion
      expanded={expanded}
      disableGutters={true}
      sx={{
        "&:before": {
          display: "none",
        },
        background: "#212121",
        border: "4px solid #fff",
        borderRadius: "20px",
        ":last-of-type": {
          borderRadius: "20px",
        },

        marginBottom: "20px",
        boxShadow: "1px 1px 1px 0px rgba(0, 0, 0, 0.10)",
        "& .Mui-expanded": {
          borderRadius: "20px 20px 0 0",
          borderBottom: 0,
        },
        "& .MuiAccordion-region": {
          borderRadius: "0 0 20px 20px",
          borderTop: 0,
        },
        "&:hover": {},
      }}
    >
      <AccordionSummary
        sx={{
          "& .Mui-expanded": { border: 0, marginBottom: 0 },
          maxWidth: "100%",
        }}
        expandIcon={
          switchable ? <img src={require("assets/img/expand.png")} /> : ""
        }
      >
        {title}
      </AccordionSummary>
      <AccordionDetails
        style={{
          background: "#2D2D2D",
          borderRadius: "0 0 20px 20px",
          padding: 0,
        }}
      >
        {content}
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(Card);
