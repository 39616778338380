import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button, Grid } from "@mui/material";
import Stats from "../components/Stats";
import Card from "components/Card/Card";
import CustomTabs from "components/CustomTabs/Tabs";
import CardTitle from "components/Card/CardTitle";
import CustomInput from "components/CustomInput/CustomInput";
import { useSelector, useDispatch } from "react-redux";
import ButtonSteps from "../components/ButtonSteps";
import Info from "../components/Info";
import { tokens } from "features/configure";
import Overlay from "components/Overlay/Overlay";
export default function Stake() {
  const [tab, setTab] = useState(0);
  const [lockAmount, setLockAmount] = useState("");
  const dashboardData = useSelector((state) => state.dashboard);
  const renderLock = () => {
    return (
      <div>
        <div className="header" style={{ marginBottom: "40px" }}>
          Lock FOCUS for 16weeks + 7 days. Locked FOCUS will earn platform fees
          as well as give voting weight for proposal and gauge weight voting.
        </div>
        <Grid container>
          <Grid item xs={12} md={6}>
            <CustomInput
              tokens={[tokens["FOCUS"]]}
              title="Amount of FOCUS to lock"
              balance={_.get(dashboardData, `data.balances.FOCUS`, 0)}
              value={lockAmount}
              onChange={setLockAmount}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ButtonSteps
              steps={[
                { label: "Approve", onClick: () => {} },
                { label: "Lock FOCUS", onClick: () => {} },
              ]}
              current={
                _.get(dashboardData, `data.allowances[FOCUS/lpxCVX"]`, 0) > 0
                  ? 1
                  : 0
              }
            />
          </Grid>
        </Grid>
      </div>
    );
  };
  const renderInfo = () => {
    return (
      <Info
        rows={[
          {
            title: "CVX token address",
            href: " ",
            content: "0xadd2F542f9FF06405Fabf8CaE4A74bD0FE29c673",
          },
          {
            title: "cvxCRV token address",
            href: " ",
            content: "0xadd2F542f9FF06405Fabf8CaE4A74bD0FE29c673",
          },
          {
            title: "Deposit contract address",
            href: " ",
            content: "0xadd2F542f9FF06405Fabf8CaE4A74bD0FE29c673",
          },
          {
            title: "cvxCRV utilities contract address",
            href: " ",
            content: "0xadd2F542f9FF06405Fabf8CaE4A74bD0FE29c673",
          },
        ]}
      />
    );
  };
  return (
    <>
      <div style={{ maxWidth: 1400, margin: "40px auto" }}>
        <Stats />
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Card
              switchable={false}
              expanded={true}
              title={
                <CardTitle
                  data={[
                    {
                      label: (
                        <div className="startRow fieldHeader">
                          <img
                            className="lgIcon"
                            src={require("assets/img/FOCUS.png")}
                          />
                          FOCUS
                        </div>
                      ),
                      size: 3,
                    },
                    { label: "$0", title: "Claimable(USD value)", size: 3 },
                    { label: "2.45%", title: "vAPR", size: 2 },
                    { label: "0 FOCUS", title: "My FOCUS Staked", size: 2 },
                    { label: "$133.3m", title: "Total Locked", size: 2 },
                  ]}
                />
              }
              content={
                <div>
                  <CustomTabs
                    value={tab}
                    onChange={(e, newValue) => {
                      setTab(newValue);
                    }}
                    tabs={[
                      { label: "LOCK", content: renderLock() },
                      { label: "INFO", content: renderInfo() },
                    ]}
                  />
                </div>
              }
            ></Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="card">
              <div className="dashboardHeader">Your current FOCUS locks</div>
              <div>
                You have no FOCUS locks. Lock FOCUS on this page to get started.
              </div>
            </div>
            <div className="card">
              <Button
                color="primary"
                fullWidth
                variant="contained"
                onClick={() => {
                  window.open("https://uncle.finance", "_blank");
                }}
              >
                Go to vote page
              </Button>
              <div style={{ textAlign: "center", marginTop: 26 }}>
                Current vote weight: --
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
