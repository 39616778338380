import Uncle from "assets/img/UNCLE.png";
import focusCVX from "assets/img/focusCVX.svg";
import CVX from "assets/img/CVX.png";
import FOCUS from "assets/img/FOCUS.png";
import { pxCvxABI, uCvxABI, cvxABI } from "./abi";
export const tokens = {
  uncleCVX: {
    symbol: "uncleCVX",
    address: "0x63121B8f2a88ea0fEF99E3a1614454Ef1837423B",
    imgUrl: Uncle,
    abi: pxCvxABI,
  },
  focusCVX: {
    symbol: "focusCVX",
    address: "0xe4aF32D9d5ADdF6D786Ed6b50C8d76c7D7541A02",
    imgUrl: focusCVX,
    abi: uCvxABI,
  },
  CVX: {
    symbol: "CVX",
    address: "0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B",
    imgUrl: CVX,
    abi: cvxABI,
  },
  UNCLE: {
    symbol: "UNCLE",
    address: "0x63121B8f2a88ea0fEF99E3a1614454Ef1837423B",
    imgUrl: Uncle,
    abi: pxCvxABI,
  },
  FOCUS: {
    symbol: "FOCUS",
    address: "0x63121B8f2a88ea0fEF99E3a1614454Ef1837423B",
    imgUrl: FOCUS,
    abi: pxCvxABI,
  },
};
