import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

export default function Footer() {
  const theme = useTheme();

  return (
    <div
      style={{
        color: "#fff",
        padding: 50,
        alignItems: "center",
      }}
      className="startRow"
    >
      <Link to="/">
        <img
          src={require("assets/img/logo.png")}
          style={{ height: 101, marginRight: 87 }}
        ></img>
      </Link>
      <div className="footerLink">
        <a href="https://twitter.com/focus_credit" target="_blank">
          X
        </a>{" "}
      </div>
      <div className="footerLink">
        <a href="https://t.me/focuscredit" target="_blank">
          Telegram
        </a>
      </div>
    </div>
  );
}
