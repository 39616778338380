import React, { Fragment, useState } from "react";
import _ from "lodash";
import { Button, Grid } from "@mui/material";
import Stats from "../components/Stats";
import Card from "components/Card/Card";
import CardTitle from "components/Card/CardTitle";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import { tokens } from "features/configure";
import Overlay from "components/Overlay/Overlay";
export default function Claim() {
  const [claimStake, setClaimStake] = useState(true);
  const renderReward = (reward) => {
    return (
      <Card
        title={
          <CardTitle
            data={[
              {
                label: (
                  <div className="startRow tableHeader">
                    <img className="lgIcon" src={reward.imgUrl} />
                    {reward.name}
                  </div>
                ),
                size: 3,
              },
              {
                label: `$${reward.claimable}`,
                title: "Claimable(USD value)",
                size: 3,
              },
              { label: `${reward.apr}%`, title: "vAPR", size: 2 },
              { label: "", title: "", size: 2 },
              {
                label: (
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    sx={{ width: "174px" }}
                  >
                    Claim All
                  </Button>
                ),
                size: 2,
              },
            ]}
          />
        }
        content={reward.content}
      ></Card>
    );
  };
  const renderTokenList = (tokenList) => {
    return (
      <div style={{ padding: 22 }}>
        <Grid container spacing={1} alignItems={"center"}>
          {_.map(tokenList, (token, i) => {
            return (
              <Fragment key={i}>
                <Grid item xs={12} md={4}>
                  <div className="startRow">
                    <img className="icon" src={token.imgUrl} />
                    {token.symbol}
                  </div>
                </Grid>
                <Grid item xs={12} md={8} style={{ color: "#1D6FFF" }}>
                  {token.value}
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </div>
    );
  };
  const renderClaimAllField = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <div className="startRow">
          <CustomSwitch
            label={<span className="listHeader">Claim All & Stake</span>}
            checked={claimStake}
            onChange={(e) => {
              setClaimStake(e.target.checked);
            }}
          />
          <div className="listHeader" style={{ color: "#F83B53" }}>
            Claim and stake all your rewards in one go
          </div>
        </div>
        {claimStake && (
          <div className="card">
            <div className="listHeader">CLAIM ALL AND STAKE</div>
            <div style={{ fontStyle: "italic", color: "#8D8D8D" }}>
              Select some rewards in order to claim them
            </div>
            <Button
              color="primary"
              variant="contained"
              size="small"
              sx={{ width: "174px", margin: "20px 0" }}
            >
              Claim All
            </Button>
            <div
              style={{
                position: "relative",
                background: "#229FFC",
                borderRadius: 20,
                padding: "20px 80px",
                maxWidth: "550px",
              }}
            >
              <img
                src={require("assets/img/dots.png")}
                style={{ position: "absolute", left: "20px", bottom: "20px" }}
              />
              <div>What this action does:</div>
              Claim all rewards from the selected staking contracts
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div style={{ maxWidth: 1400, margin: "40px auto" }}>
        <Stats />
        {renderClaimAllField()}
        <div className="fieldHeader" style={{ marginBottom: 10 }}>
          Claim earnings
        </div>
        {_.map(
          [
            {
              name: "CVX Pools",
              imgUrl: require("assets/img/CVX.png"),
              claimable: 0,
              apr: 2.45,
              content: renderTokenList([
                { ...tokens.focusCVX, value: 10 },
                { ...tokens.focusCVX, value: 10 },
              ]),
            },
            {
              name: "Staked FOCUS",
              imgUrl: require("assets/img/FOCUS.png"),
              claimable: 0,
              apr: 2.45,
              content: renderTokenList([{ ...tokens.FOCUS, value: 10 }]),
            },
            {
              name: "Locked FOCUS",
              imgUrl: require("assets/img/FOCUS.png"),
              claimable: 0,
              apr: 2.45,
              content: renderTokenList([{ ...tokens.FOCUS, value: 10 }]),
            },
          ],
          (reward) => {
            return renderReward(reward);
          }
        )}
      </div>
    </>
  );
}
