import React, { memo } from "react";
import {
  Table,
  Grid,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useTheme, styled } from "@mui/material/styles";
import _ from "lodash";

const CustomTable = ({
  headers,
  contents,
  leftText = { 0: true },
  style = {},
  maxHeight = "375px",
}) => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "8px",

    [`&.${tableCellClasses.head}`]: {
      color: "red",
      border: "0px",
      fontSize: "20px",
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      color: "#FFFFFF",
      fontSize: "20px",
      fontWeight: 600,
      borderBottom: "1px solid #4B4B4B",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      borderBottom: "1px solid #4B4B4B",
    },
    // hide last border
  }));
  if (computer) {
    return (
      <TableContainer sx={{ maxHeight, overflow: "auto" }}>
        <Table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0 1em",
          }}
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => {
                return (
                  <StyledTableCell
                    key={index}
                    align={leftText[index] == true ? "left" : "center"}
                    style={{ ...style }}
                  >
                    {header}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(contents, (row, index) => (
              <StyledTableRow
                key={index}
                style={{
                  borderRadius: 10,
                  marginBottom: 10,
                }}
              >
                {_.map(row, (r, rIndex) => (
                  <StyledTableCell
                    key={rIndex}
                    align={leftText[rIndex] == true ? "left" : "center"}
                    style={{ ...style }}
                  >
                    {r}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <TableContainer sx={{ maxHeight, overflow: "auto" }}>
        <Table size="small" style={{}}>
          <TableBody>
            {_.map(contents, (row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell style={{ ...style }}>
                  {_.map(row, (r, rIndex) => {
                    return (
                      <Grid container style={{ lineHeight: 2 }}>
                        <Grid item xs={5}>
                          {headers[rIndex]}
                        </Grid>{" "}
                        <Grid item xs={7}>
                          {r}
                        </Grid>
                      </Grid>
                    );
                  })}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export default memo(CustomTable);
