import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
const Stats = ({}) => {
  const StyledCard = styled((props) => <div {...props} />)({
    borderRadius: 20,
    height: 155,
    color: "#FFF",
    fontWeight: 600,
    fontSize: 30,
    textAlign: "center",
    padding: 30,
    letterSpacing: 5,
    position: "relative",
  });
  const StyledValue = styled((props) => <div {...props} />)({
    fontSize: 40,
  });
  const StyledDots = styled((props) => (
    <img src={require("assets/img/dots.png")} {...props} />
  ))({
    position: "absolute",
    left: "20px",
    bottom: "20px",
  });

  return (
    <div style={{ margin: "86px auto", maxWidth: 1000 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <StyledCard style={{ background: "#F83B53" }}>
            Total Clamable<StyledValue>$0</StyledValue>
            <StyledDots />
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledCard style={{ background: "#229FFC" }}>
            Total Deposits<StyledValue>$0</StyledValue>
            <StyledDots />
          </StyledCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default Stats;
