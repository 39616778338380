import { createBrowserRouter, useParams } from "react-router-dom";
import {
  NotFound,
  App,
  Landing,
  Stake,
  Lock,
  Claim,
  Game,
} from "features/home";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <NotFound />,
    element: <App />,
    children: [
      { index: true, element: <Landing /> },
      { path: "stake", element: <Stake /> },
      { path: "lock", element: <Lock /> },
      { path: "claim", element: <Claim /> },
      { path: "game", element: <Game /> },
    ],
  },
]);
export default router;
