import React, { useEffect, useState } from "react";
import CustomTabs from "components/CustomTabs/Tabs";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Switch, FormControlLabel } from "@mui/material";
import Card from "components/Card/Card";
import CardTitle from "components/Card/CardTitle";
import { styled } from "@mui/material/styles";
import CustomInput from "components/CustomInput/CustomInput";
import { tokens } from "features/configure";
import ButtonSteps from "../components/ButtonSteps";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import Info from "../components/Info";
import RewardInfo from "../components/RewardInfo";
export default function Convert() {
  const [tab, setTab] = useState(0);
  const [advanced, setAdvanced] = useState(false);
  const dashboardData = useSelector((state) => state.dashboard);
  const [convertStakeAmount, setConvertStakeAmount] = useState("");
  const [convertAmount, setConvertAmount] = useState("");
  const [stakeAmount, setStakeAmount] = useState("");
  const [unstakeAmount, setUnstakeAmount] = useState("");
  const StyledContainer = styled((props) => <div {...props} />)({
    padding: 22,
  });

  const renderConverStake = () => {
    return (
      <StyledContainer>
        <Grid container>
          <Grid item xs={12} md={10}>
            <div className="header">
              Convert CRV to cvxCRV. By staking cvxCRV, you’re earning the usual
              rewards from veCRV (3crv governance fee distribution from Curve +
              any airdrop), plus a share of 10% of the Convex LPs’ boosted CRV
              earnings, and CVX tokens on top of that.
            </div>
            <div
              className="header"
              style={{
                marginTop: 10,
                fontStyle: "italic",
                marginBottom: 37,
                fontSize: 16,
              }}
            >
              Important: Converting CRV to cvxCRV is irreversible. You may stake
              and unstake cvxCRV tokens, but not convert them back to CRV.
              Secondary markets however exist to allow the exchange of cvxCRV
              for CRV at varying market rates.
            </div>
            <div className="header">Convert and stake CRV in one go:</div>
            <Grid container>
              <Grid item xs={12} md={6}>
                <CustomInput
                  tokens={[tokens["CVX"]]}
                  title="Amount of CVX to convert and stake"
                  balance={_.get(dashboardData, `data.balances.CVX`, 0)}
                  value={convertStakeAmount}
                  onChange={setConvertStakeAmount}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ButtonSteps
                  steps={[
                    { label: "Approve", onClick: () => {} },
                    { label: "Convert & Stake", onClick: () => {} },
                  ]}
                  current={
                    _.get(dashboardData, `data.allowances[CVX/lpxCVX"]`, 0) > 0
                      ? 1
                      : 0
                  }
                />
              </Grid>
            </Grid>
            {advanced && (
              <div>
                {" "}
                <div className="header" style={{ marginTop: 30 }}>
                  Or separately convert CRV into cvxCRV with this first form,
                  and stake cvxCRV with this second form:
                </div>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <CustomInput
                      tokens={[tokens["CVX"]]}
                      title="Amount of CVX to convert"
                      balance={_.get(dashboardData, `data.balances.CVX`, 0)}
                      value={convertAmount}
                      onChange={setConvertAmount}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ButtonSteps
                      steps={[
                        { label: "Approve", onClick: () => {} },
                        { label: "Convert", onClick: () => {} },
                      ]}
                      current={
                        _.get(
                          dashboardData,
                          `data.allowances[CVX/lpxCVX"]`,
                          0
                        ) > 0
                          ? 1
                          : 0
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomInput
                      tokens={[tokens["uncleCVX"]]}
                      title="Amount of CVX to convert"
                      balance={_.get(
                        dashboardData,
                        `data.balances.uncleCVX`,
                        0
                      )}
                      value={stakeAmount}
                      onChange={setStakeAmount}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ButtonSteps
                      steps={[
                        { label: "Approve", onClick: () => {} },
                        { label: "Stake", onClick: () => {} },
                      ]}
                      current={
                        _.get(
                          dashboardData,
                          `data.allowances[CVX/lpxCVX"]`,
                          0
                        ) > 0
                          ? 1
                          : 0
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={2} sx={{ textAlign: "right" }}>
            <CustomSwitch
              checked={advanced}
              onChange={(e) => setAdvanced(e.target.checked)}
              label={"Advanced"}
            />
          </Grid>
        </Grid>
      </StyledContainer>
    );
  };

  const renderConverUnstake = () => {
    return (
      <StyledContainer>
        {" "}
        <div className="header">
          Unstake cvxCRV. Note that unstaked cvxCRV doesn’t earn anything: any
          and all rewards only accrue to staked cvxCRV.:
        </div>
        <Grid container>
          <Grid item xs={12} md={6}>
            <CustomInput
              tokens={[tokens["CVX"]]}
              title="Amount of CVX to unstake"
              balance={_.get(dashboardData, `data.balances.CVX`, 0)}
              value={unstakeAmount}
              onChange={setUnstakeAmount}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ButtonSteps
              steps={[
                { label: "Approve", onClick: () => {} },
                { label: "Convert & Stake", onClick: () => {} },
              ]}
              current={
                _.get(dashboardData, `data.allowances[CVX/lpxCVX"]`, 0) > 0
                  ? 1
                  : 0
              }
            />
          </Grid>
        </Grid>
      </StyledContainer>
    );
  };

  const renderInfo = () => {
    return (
      <Info
        rows={[
          {
            title: "CVX token address",
            href: " ",
            content: "0xadd2F542f9FF06405Fabf8CaE4A74bD0FE29c673",
          },
          {
            title: "cvxCRV token address",
            href: " ",
            content: "0xadd2F542f9FF06405Fabf8CaE4A74bD0FE29c673",
          },
          {
            title: "Deposit contract address",
            href: " ",
            content: "0xadd2F542f9FF06405Fabf8CaE4A74bD0FE29c673",
          },
          {
            title: "cvxCRV utilities contract address",
            href: " ",
            content: "0xadd2F542f9FF06405Fabf8CaE4A74bD0FE29c673",
          },
        ]}
      />
    );
  };

  return (
    <div style={{ maxWidth: 1400, margin: "40px auto" }}>
      <Card
        switchable={false}
        expanded={true}
        title={
          <CardTitle
            data={[
              {
                label: (
                  <div className="startRow">
                    <img
                      className="lgIcon"
                      src={require("assets/img/CVX.png")}
                    />
                    <span className="fieldHeader">CVX</span>
                  </div>
                ),
                size: 2,
              },
              { label: "$0", title: "Claimable(USD value)", size: 2 },
              { label: "$0", title: "My vAPR", size: 2 },
              {
                label: "17.28% | 16.47%",
                title: (
                  <div>
                    Max vAPR{" "}
                    <RewardInfo
                      apr={2}
                      title={
                        <div className="startRow">
                          <img
                            className="icon"
                            src={require("assets/img/CVX.png")}
                          />
                          convert CVX rewards
                        </div>
                      }
                    />
                  </div>
                ),
                size: 2,
              },
              { label: "0 uncleCVX", title: "My uncleCVX Staked", size: 2 },
              { label: "$133.3m", title: "TVL", size: 2 },
            ]}
          />
        }
        content={
          <div>
            <CustomTabs
              value={tab}
              onChange={(e, newValue) => {
                setTab(newValue);
              }}
              tabs={[
                { label: "CONVERT/STAKE", content: renderConverStake() },
                { label: "UNSTAKE", content: renderConverUnstake() },
                { label: "INFO", content: renderInfo() },
                {
                  label: "REEDEEM",
                  content: (
                    <div>
                      Go to{" "}
                      <a href="https://uncle.finance" target="_blank">
                        Uncle Finance
                      </a>
                    </div>
                  ),
                  onClick: () => {
                    window.open("https://uncle.finance", "_blank");
                  },
                },
              ]}
            />
          </div>
        }
      ></Card>
    </div>
  );
}
