import React, { useState } from "react";
import ConnectWallet from "../../../components/ConnectWallet/ConnectWallet";
import {
  Grid,
  AppBar,
  CssBaseline,
  Toolbar,
  IconButton,
  Drawer,
  List,
} from "@mui/material";
import MenuItems from "./MenuItems";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "assets/img/logo.png";
import smLogo from "assets/img/logo.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

export default function Header() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <div
      style={{
        position: "relative",
        background: "transparent",
        height: 60,
        padding: 3,
        marginBottom: 80,
      }}
    >
      <AppBar
        position="sticky"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          display: {
            lg: "none",
            md: "block",
          },
          top: 0,
        }}
      >
        <Toolbar>
          <Grid justifyContent="space-between" container alignItems={"center"}>
            <Grid item>
              <div style={{ color: "white", fontSize: 24, padding: 5 }}>
                <img
                  src={smLogo}
                  style={{
                    height: "25px",
                    marginTop: 10,
                    objectFit: "contain",
                  }}
                />
              </div>
            </Grid>

            <Grid item>
              <IconButton
                aria-label="open drawer"
                style={{ color: "white" }}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>

        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          <div
            style={{
              textAlign: "center",
              width: "60vw",
              background: theme.palette.background.main,
              height: "100vh",
            }}
            className="column"
          >
            <MenuItems
              handleDrawerToggle={handleDrawerToggle}
              flexDirection="column"
            />
            <ConnectWallet />
          </div>
        </Drawer>
      </AppBar>

      <div
        className="betweenRow"
        style={{
          padding: "0 50px",
          display: computer ? "flex" : "none",
        }}
      >
        <Link to="/">
          <img src={logo} style={{ height: "101px" }} />
        </Link>
        <MenuItems />
        <ConnectWallet />
      </div>
    </div>
  );
}
